import { useState } from "react";

//import scss
import "./Dropdown.scss";

function Dropdown({
  title,
  content,
}) {

  const [isActive, setIsActive] = useState(false);
  return (
    
      <div className="container-dropdown">
        <div
          className="wrapper-dropdown"
          onClick={(e) => setIsActive(!isActive)}
        >
          {!isActive && (
            <div className="wrapper-underTitle">
              <p>{title}</p> <span>+</span>
            </div>
          )}

          {isActive && (
            <div className="wrapper-underTitle">
              <p>{title}</p> <span>-</span>
            </div>
          )}

          {isActive && (
            <div className="dropdown-content">
              <p>
              {content}
              </p>
            </div>
          )}

        </div>
      </div>   
  );
}

export default Dropdown;
