
//import scss
import "../../../../utils/styles/GlobalStyle.scss";
import "../../Insurance.scss";

//import composant
import Navbar from "../../../../components/Navbar";

function Health() {
  return (
    <div>
      <Navbar />
      <section className="container-insurance">
  <div className="container-information">
    <h2>Toutes les assurances vélo et trottinette</h2>

    <p>
      Choisissez votre profil et indiquez vos besoins de couverture pour votre vélo ou trottinette afin d'obtenir un devis gratuit, rapide et personnalisé de la meilleure assurance disponible.
    </p>
  </div>
  <div className="container-estimate">
    <h2>Votre devis en quelques minutes</h2>
    <h3>Quel véhicule souhaitez-vous assurer ?</h3>

    <div className="tarif-button">
      <a href="particular/form">Obtenir un tarif</a>
    </div>
  </div>
</section>
    </div>
  );
}
export default Health;
