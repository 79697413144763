//import scss
import "../../../utils/styles/GlobalStyle.scss";
import "../Insurance.scss";

//import composant
import Navbar from "../../../components/Navbar";

function Health() {
  return (
    <div>
      <Navbar />
      <section className="container-insurance">
        <div className="container-information">
          <h2>Toutes les assurances emprunteur</h2>

          <p>
            Choisissez votre profil et précisez vos besoins en matière de
            couverture pour obtenir un devis gratuit, rapide et personnalisé de
            la meilleure assurance emprunteur disponible.
          </p>
        </div>
        <div className="container-estimate">
          <h2>Votre devis emprunteur en quelques minutes</h2>
          <h3>Qui souhaitez-vous assurer ?</h3>

          <div className="tarif-button">
            <a href="printer/form">Obtenir un tarif</a>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Health;
