//import scss
import "../../../../utils/styles/GlobalStyle.scss";
import "../../Insurance.scss";

//import composant
import Navbar from "../../../../components/Navbar";

function Health() {
  return (
    <div>
      <Navbar />
      <section className="container-insurance">
        <div className="container-information">
          <h2>Toutes les solutions de prévoyance professionnelle</h2>

          <p>
            Choisissez votre profil et indiquez vos besoins de couverture en
            matière de prévoyance professionnelle pour obtenir un devis gratuit,
            rapide et personnalisé. Protégez-vous contre les imprévus tels que
            l'invalidité, l'incapacité de travail ou encore le décès, avec les
            meilleures garanties adaptées à votre situation.
          </p>
        </div>
        <div className="container-estimate">
          <h2>Votre devis prévoyance professionnelle en quelques minutes</h2>
          <h3>Quels types de garanties souhaitez-vous souscrire ?</h3>

          <div className="tarif-button">
            <a href="professional/form">Obtenir un tarif</a>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Health;