//import data

//import scss
import "../../utils/styles/GlobalStyle.scss";
import "./Confidentialite.scss";
import logoCard from "../../assets/image/logo.png";

//import composant
import Navbar from "../../components/Navbar";

function Contact() {
  return (
    <div>
      <Navbar />
      <section className="container-confidentialite">
        <h1>
          
          Politique de Confidentialité Date d'entrée en vigueur : 04/10/2024
        </h1>
        <p>
          1. Introduction Nous nous engageons à protéger votre vie privée. Cette
          politique de confidentialité décrit comment Gold ("nous", "notre",
          "nos") collecte, utilise, et partage vos informations personnelles
          lorsque vous visitez notre site web http://www.goldassurance.fr/home.
        </p>
        <p>
          
          2. Informations que nous collectons Nous collectons plusieurs types
          d'informations sur nos utilisateurs, y compris : Données personnelles
          : Lorsque vous vous inscrivez à nos services, remplissez un formulaire
          ou interagissez avec notre site, nous pouvons collecter des
          informations telles que votre nom, adresse e-mail, numéro de
          téléphone, et toute autre information que vous choisissez de fournir.
          Données non personnelles : Nous recueillons également des informations
          non personnelles, comme des données d'utilisation, des adresses IP, et
          des informations sur votre appareil.
        </p>
        <p>
          
          3. Comment nous utilisons vos informations Nous utilisons les
          informations que nous collectons pour : Fournir, exploiter et
          maintenir nos services. Améliorer, personnaliser et étendre nos
          services. Communiquer avec vous, y compris pour le service client.
          Traiter vos transactions et vous envoyer des confirmations. Vous
          envoyer des mises à jour, des newsletters, des promotions et d'autres
          informations.
        </p>
        <p>
          
          4. Base légale pour le traitement des données Nous traitons vos
          données personnelles sur les bases légales suivantes : Consentement :
          Vous avez consenti au traitement de vos données à des fins
          spécifiques. Contrat : Le traitement est nécessaire à l'exécution d'un
          contrat auquel vous êtes partie. Obligation légale : Le traitement est
          nécessaire pour respecter une obligation légale.
        </p>
        <p>
          5. Partage de vos informations Nous ne partageons pas vos informations
          personnelles avec des tiers sans votre consentement, sauf dans les cas
          suivants : Avec des prestataires de services qui nous aident à fournir
          nos services. Pour respecter les lois, réglementations ou demandes
          légales. Dans le cadre d'une fusion, acquisition ou vente d'actifs.
        </p>
        <p>
          6. Sécurité de vos informations Nous mettons en œuvre des mesures de
          sécurité appropriées pour protéger vos données personnelles contre
          l'accès, l'utilisation, la divulgation, l'altération ou la destruction
          non autorisés. Cependant, aucune méthode de transmission sur Internet
          ou de stockage électronique n'est totalement sûre, et nous ne pouvons
          garantir une sécurité absolue.
        </p>

        <p>
          7. Vos droits Conformément au RGPD, vous avez le droit de : Accéder à
          vos données personnelles. Rectifier vos données personnelles. Demander
          la suppression de vos données personnelles. Limiter ou s'opposer à
          leur traitement. Demander la portabilité de vos données. Pour exercer
          ces droits, veuillez nous contacter à l'adresse suivante :
          Contact@goldassurance.fr.
        </p>
        <p>
          8. Modifications de cette politique Nous nous réservons le droit de
          modifier cette politique de confidentialité à tout moment. Nous vous
          informerons de tout changement en publiant la nouvelle politique sur
          cette page. Nous vous encourageons à consulter régulièrement cette
          politique pour rester informé sur la manière dont nous protégeons vos
          informations.
        </p>
        <p>
          
          9. Contact Pour toute question concernant cette politique de
          confidentialité, veuillez nous contacter à : Gold
          Adresse : BUREAU 562, 78 AVENUE DES CHAMPS ELYSEES, 75008 PARIS Email : Contact@goldassurance.fr. Téléphone : 06 35 53 39 35
        </p>
      </section>
    </div>
  );
}
export default Contact;
