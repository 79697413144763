import React, { useState } from 'react';
import Navbar from "../../../../components/Navbar";
import "../../../../utils/styles/GlobalStyle.scss";
import "../../InsuranceForm.scss";

const Form = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    bonusMalus: '',
    ancienneteAssuree: '',
    anneePermis: '',
    immatriculation: '',
    messages: ""
  });

  const [errors, setErrors] = useState({});

  // Gestion des changements dans les champs du formulaire
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://backend.goldassurance.fr/api/Particular/auto', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log(formData)
      if (response.status === 201) {
        alert("Questionnaire soumis avec succès");
        setErrors({});
        setFormData({
          nom: '',
          prenom: '',
          email: '',
          telephone: '',
          bonusMalus: '',
          ancienneteAssuree: '',
          anneePermis: '',
          immatriculation: '',
          messages: "",
        });
      } else if (response.status === 400) {
        setErrors(result.errors);
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire :", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className='wraper-page'>
        <form className="container-insurance-form" onSubmit={handleSubmit}>
          <div>
            <label>Nom :</label>
            <input
              type="text"
              name="nom"
              value={formData.nom}
              onChange={handleChange}
            />
            {errors.nom && <p>{errors.nom}</p>}
          </div>
          <div>
            <label>Prénom :</label>
            <input
              type="text"
              name="prenom"
              value={formData.prenom}
              onChange={handleChange}
            />
            {errors.prenom && <p>{errors.prenom}</p>}
          </div>
          <div>
            <label>Email :</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p>{errors.email}</p>}
          </div>
          <div>
            <label>Telephone :</label>
            <input
              type="tel"
              name="telephone"
              value={formData.telephone}
              onChange={handleChange}
            />
            {errors.telephone && <p>{errors.telephone}</p>}
          </div>
          <div>
            <label>Bonus/Malus :</label>
            <input
              type="text"
              name="bonusMalus"
              value={formData.bonusMalus}
              onChange={handleChange}
            />
            {errors.bonusMalus && <p>{errors.bonusMalus}</p>}
          </div>
          <div>
            <label>Ancienneté Assurée :</label>
            <input
              type="text"
              name="ancienneteAssuree"
              value={formData.ancienneteAssuree}
              onChange={handleChange}
            />
            {errors.ancienneteAssuree && <p>{errors.ancienneteAssuree}</p>}
          </div>
          <div>
            <label>Année Permis :</label>
            <input
              type="text"
              name="anneePermis"
              value={formData.anneePermis}
              onChange={handleChange}
            />
            {errors.anneePermis && <p>{errors.anneePermis}</p>}
          </div>
          <div>
            <label>Immatriculation :</label>
            <input
              type="text"
              name="immatriculation"
              value={formData.immatriculation}
              onChange={handleChange}
            />
            {errors.immatriculation && <p>{errors.immatriculation}</p>}
          </div>
          <div>
            <label>
              Messages :
              <textarea
                name="messages"
                value={formData.messages}
                onChange={handleChange}
              />
            </label>
          </div>
          <button type="submit">Soumettre</button>
        </form>
      </div>
    </div>
  );
};

export default Form;
