import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import isAuthenticated from "../../../utils/auth/auth";
import "../../../utils/styles/GlobalStyle.scss";
import "../Details/Details.scss";
import Navbar from "../../../components/Navbar";
import { useParams } from "react-router-dom";

async function fetchClientData() {
  const response = await fetch("https://backend.goldassurance.fr/api/clients"); // URL de l'API
  if (!response.ok) {
    throw new Error("Erreur lors de la récupération des données");
  }
  return await response.json();
}

function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login"); // Redirect if not authenticated
    } else {
      fetchClientData(); // Call API to fetch clients
    }
  }, [navigate]);

  const { id } = useParams(); // Récupérer l'ID de l'URL
  const [client, setClient] = useState(null); // Stocker le client récupéré
  const [loading, setLoading] = useState(true); // Gérer l'état de chargement
  const [error, setError] = useState(null); // Gérer les erreurs

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchClientData();
        const foundClient = data.customers.find((client) => client._id === id);

        const foundClientPrinter = data.forms.find(
          (client) => client._id === id
        );

        const foundClientParticulierAuto = data.particulierAutos.find(
          (client) => client._id === id
        );

        const foundClientParticuliers = data.particuliers.find(
          (client) => client._id === id
        );

        const foundClientPros = data.pros.find(
          (client) => client._id === id
        );
        
        if (foundClientPrinter || foundClient ||foundClientParticulierAuto || foundClientParticuliers || foundClientPros) {
          setClient(foundClient || foundClientPrinter || foundClientParticulierAuto || foundClientParticuliers || foundClientPros);
        } else {
          window.location.href = "/404"; // Rediriger si le client n'est pas trouvé
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [id]);

  if (loading) {
    return <div>Chargement...</div>; // Affichage pendant le chargement
  }

  if (error) {
    return <div>Erreur : {error}</div>; // Affichage en cas d'erreur
  }

  if (!client) {
    return null; // Retourne null si aucun client n'a été trouvé (par sécurité)
  }

  // Trouver l'entrée "Messages" dans les questions du client

  // Analyser les futurs assurés si disponibles
  const futursAssures = client.questions.find(
    (question) => question.label === "Qui sont les futurs assurés ?"
  )?.reponse;
  const parsedAssures = futursAssures ? JSON.parse(futursAssures) : [];

  const futursAssuresPrinter = client.questions.find(
    (question) => question.label === "Assuré Principal"
  )?.reponse;

  const parsedAssuresPrinter = futursAssuresPrinter
    ? JSON.parse(futursAssuresPrinter)
    : [];

  const futursAssuresPrinterCo = client.questions.find(
    (question) => question.label === "Co-emprunteur"
  )?.reponse;

  const parsedAssuresPrinterCo = futursAssuresPrinterCo
    ? JSON.parse(futursAssuresPrinterCo)
    : [];

 
  return (
    <div>
      <Navbar />
      <h1 className="title-detail-client">Détails du client</h1>
      <div className="Container-questionnaire-detail">
        <h2>Questions</h2>
        <ul>
          {client.questions
            .filter(
              (question) =>
                question.label !== "Qui sont les futurs assurés ?" &&
                question.label !== "Co-emprunteur" &&
                question.label !== "Assuré Principal"
            ) // Filtrer pour exclure ces questions
            .map((question) => (
              <li key={question._id}>
                <strong>{question.label} :</strong> {question.reponse}
              </li>
            ))}
        </ul>
      </div>

      {/* Futurs Assurés */}
     
      {parsedAssures.length > 0 && (
        <div className="Container-questionnaire-detail">
          <h2>Futurs assurés :</h2>
          <ul>
            {parsedAssures.map((assure, index) => (
              <li key={assure.assuranceNumber} style={{ marginBottom: "10px" }}>
                <strong>Assuré n°{index + 1} :</strong> {/* Numérotation ici */}
                {console.log(assure)}
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    <strong>Civilité :</strong> {assure.civilite}
                  </li>
                  <li>
                    <strong>Prénom :</strong> {assure.prenom}
                  </li>
                  <li>
                    <strong>Nom :</strong> {assure.nom}
                  </li>
                  <li>
                    <strong>Email :</strong> {assure.email}
                  </li>
                  <li>
                    <strong>Telephone :</strong> {assure.telephone}
                  </li>
                  <li>
                    <strong>Date de naissance :</strong> {assure.dateNaissance}
                  </li>
                  <li>
                    <strong>Code postal :</strong> {assure.paysCodePostal}
                  </li>
                  <li>
                    <strong>Régime obligatoire :</strong>{" "}
                    {assure.regimeObligatoire}
                  </li>
                  <li>
                    <strong>Statut :</strong> {assure.statut}
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Assuré Principal */}
      {parsedAssuresPrinter && Object.keys(parsedAssuresPrinter).length > 0 && (
        <div className="Container-questionnaire-detail">
          <h2>Assuré Principal :</h2>
          <ul>
            <li>
              <strong>Assuré n°1 :</strong>
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  <strong>Civilité :</strong> {parsedAssuresPrinter.civilite}
                </li>
                <li>
                  <strong>Prénom :</strong> {parsedAssuresPrinter.prenom}
                </li>
                <li>
                  <strong>Nom :</strong> {parsedAssuresPrinter.nom}
                </li>
                <li>
                  <strong>Email :</strong> {parsedAssuresPrinter.email}
                </li>
                <li>
                  <strong>Téléphone :</strong> {parsedAssuresPrinter.telephone}
                </li>
                <li>
                  <strong>Date de naissance :</strong>{" "}
                  {parsedAssuresPrinter.dateNaissance}
                </li>
                <li>
                  <strong>Code postal :</strong>{" "}
                  {parsedAssuresPrinter.paysCodePostal}
                </li>
                <li>
                  <strong>Profession :</strong>{" "}
                  {parsedAssuresPrinter.profession}
                </li>
                <li>
                  <strong>Statut :</strong> {parsedAssuresPrinter.statut}
                </li>
                <li>
                  <strong>Fumeur :</strong> {parsedAssuresPrinter.fumeur}
                </li>
                <li>
                  <strong>Déménagement à l'étranger :</strong>{" "}
                  {parsedAssuresPrinter.demenagementEtranger}
                </li>
                <li>
                  <strong>Déplacement professionnel :</strong>{" "}
                  {parsedAssuresPrinter.deplacementPro}
                </li>
                <li>
                  <strong>Manutention de charges lourdes :</strong>{" "}
                  {parsedAssuresPrinter.manutentionChargesLourdes}
                </li>
                <li>
                  <strong>Activité en hauteur :</strong>{" "}
                  {parsedAssuresPrinter.activiteHauteur}
                </li>
                <li>
                  <strong>Crédits en cours :</strong>{" "}
                  {parsedAssuresPrinter.creditsEnCours}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      )}


 {/* Co-emprunteurs */}
{parsedAssuresPrinterCo && Object.keys(parsedAssuresPrinterCo).length > 0 && (
  <div className="Container-questionnaire-detail">
    <h2>Co-emprunteurs :</h2>
    <ul>
      <li>
        <strong>Co-emprunteur :</strong>
        <ul style={{ paddingLeft: "20px" }}>
          <li>
            <strong>Civilité :</strong> {parsedAssuresPrinterCo.civilite}
          </li>
          <li>
            <strong>Prénom :</strong> {parsedAssuresPrinterCo.prenom}
          </li>
          <li>
            <strong>Nom :</strong> {parsedAssuresPrinterCo.nom}
          </li>
          <li>
            <strong>Email :</strong> {parsedAssuresPrinterCo.email}
          </li>
          <li>
            <strong>Téléphone :</strong> {parsedAssuresPrinterCo.telephone}
          </li>
          <li>
            <strong>Date de naissance :</strong> {parsedAssuresPrinterCo.dateNaissance}
          </li>
          <li>
            <strong>Code postal :</strong> {parsedAssuresPrinterCo.paysCodePostal}
          </li>
          <li>
            <strong>Profession :</strong> {parsedAssuresPrinterCo.profession}
          </li>
          <li>
            <strong>Statut :</strong> {parsedAssuresPrinterCo.statut}
          </li>
          <li>
            <strong>Fumeur :</strong> {parsedAssuresPrinterCo.fumeur}
          </li>
          <li>
            <strong>Déménagement à l'étranger :</strong> {parsedAssuresPrinterCo.demenagementEtranger}
          </li>
          <li>
            <strong>Déplacement professionnel :</strong> {parsedAssuresPrinterCo.deplacementPro}
          </li>
          <li>
            <strong>Manutention de charges lourdes :</strong> {parsedAssuresPrinterCo.manutentionChargesLourdes}
          </li>
          <li>
            <strong>Crédits en cours :</strong> {parsedAssuresPrinterCo.creditsEnCours}
          </li>
        </ul>
      </li>
    </ul>
  </div>
)}

    </div>
  );
}

export default Dashboard;
