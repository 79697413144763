import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Error from './pages/Error'
import Contact from './pages/Contact'
import Footer from './components/Footer'
import Login from './pages/Login/Index'


import Dashboard from './pages/Dashboard/index.jsx'
import Details from './pages/Dashboard/Details/index.jsx'

import Confidentialite from './pages/Confidentialite/index.jsx'

//Assurances
import Health from './pages/Insurance/health'
import HealthYou from './pages/Insurance/health/form/you'
import Printer from './pages/Insurance/printer'
import InsurancePrinter from './pages/Insurance/printer/form/printer.jsx'

import Bailleur from './pages/Insurance/Particular/Bailleur/index.jsx'
import Habitation from './pages/Insurance/Particular/Habitation/index.jsx'
import TwoWheels from './pages/Insurance/Particular/Two-wheels/index.jsx'
import Auto from './pages/Insurance/Particular/Auto/index.jsx'
import RCDivers from './pages/Insurance/Particular/RCDivers/index.jsx'
import Juridique from './pages/Insurance/Particular/juridique/index.jsx'
import Velo from './pages/Insurance/Particular/Velo/index.jsx'


import InsuranceParticular from './pages/Insurance/Particular/form/particularAuto.jsx'
import InsuranceParticularOther from './pages/Insurance/Particular/form/particular.jsx'

import AutoPro from './pages/Insurance/Pro/autoPro/index.jsx'
import Cyber from './pages/Insurance/Pro/Cyber/index.jsx'
import JuridiquePro from './pages/Insurance/Pro/JuridiquePro/index.jsx'
import MotoPro from './pages/Insurance/Pro/prevoyance/index.jsx'
import Multirisque from './pages/Insurance/Pro/Multirisque/index.jsx'
import RCDecenale from './pages/Insurance/Pro/RCDecenale/index.jsx'
import RCDiversPro from './pages/Insurance/Pro/RCDivers'
import SanteTNS from './pages/Insurance/Pro/SanteTNS/index.jsx'


import InsuranceProfessional from './pages/Insurance/Pro/form/professional.jsx'



ReactDOM.render(
  <div className="align-page">
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />

        {/*---------------Roots assurances-----------------*/}
        {/*---Roots health---*/}
        <Route path="insurance/health" element={<Health />} />
        <Route path="insurance/health/form" element={<HealthYou />} />
        {/*---Roots printer---*/}
        <Route path="insurance/printer" element={<Printer />} />
        <Route path="insurance/printer/form" element={<InsurancePrinter/>} />

        {/*---------------Roots assurance particular-----------------*/}
      
        <Route path="insurance/velo-trotinette" element={<Velo/>} />
 
        <Route path="insurance/bailleur" element={<Bailleur/>} />
       
        {/*---Two-wheels---*/}
        <Route path="insurance/tow-weels" element={< TwoWheels/>} />
        {/*---Auto---*/}
        <Route path="insurance/auto" element={<Auto />} />

        <Route path="insurance/habitation" element={<Habitation />} />
        <Route path="insurance/rc-divers" element={<RCDivers/>} />
        <Route path="insurance/juridique" element={<Juridique />} />
         {/*---Form---*/}
        <Route path="insurance/particular/form" element={<InsuranceParticularOther/>} />
        <Route path="insurance/particular/form-auto" element={<InsuranceParticular/>} />

        {/*---------------Roots assurance profesional-----------------*/}

        <Route path="insurance/auto-pro" element={<AutoPro />} />

        <Route path="insurance/cyber" element={<Cyber/>} />

        <Route path="insurance/juridique-pro" element={<JuridiquePro />} />

        <Route path="insurance/moto-pro" element={<MotoPro />} />

        <Route path="insurance/multirisque" element={<Multirisque />} />

        <Route path="insurance/rc-divers-pro" element={<RCDiversPro />} />

        <Route path="insurance/rc-decenale" element={<RCDecenale />} />

        <Route path="insurance/sante-tns" element={<SanteTNS />} />
        {/*---Form---*/}
        <Route path="insurance/professional/form" element={<InsuranceProfessional/>} />

        {/*---Login---*/}
        <Route path="/login" element={<Login />} />

        {/*---Dashboard---*/}
         <Route path="/dashboard" element={<Dashboard />} />
         <Route path="/detail-client/:id" element={<Details />} />
        
        {/*---Politique de confidentialité---*/}
        <Route path="/confidentialite" element={<Confidentialite />} />
        
        <Route path="/404" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </div>,
  document.getElementById('root')
)
