//import scss
import "../../../../utils/styles/GlobalStyle.scss";
import "../../Insurance.scss";

//import composant
import Navbar from "../../../../components/Navbar";

function Health() {
  return (
    <div>
      <Navbar />
      <section className="container-insurance">
        <div className="container-information">
          <h2>Toutes les assurances cyber</h2>

          <p>
            Sélectionnez votre profil et indiquez vos besoins de couverture en
            matière de cybersécurité pour obtenir un devis gratuit, rapide et
            personnalisé de la meilleure assurance cyber disponible.
          </p>
        </div>
        <div className="container-estimate">
          <h2>Votre devis assurance cyber en quelques minutes</h2>
          <h3>Quel type de protection recherchez-vous ?</h3>

          <div className="tarif-button">
            <a href="professional/form">Obtenir un tarif</a>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Health;
