//import image
import logo from '../../assets/image/logo.png'

//import scss
import './Footer.scss'


function Footer() {
  return (
    <footer className='background'>
      <div className="responsive-logo">
      </div>
      <div className="wrapper-footer-content">
      <img className='logo' src={logo} alt="logo" />
      <a href="/confidentialite">Politique de confidentialité</a>
      <p className='copyrate'>© 2024 Gold. All rights reserved</p>
      </div>

    </footer>
  )
}

export default Footer
