import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import image
import logo from "../../assets/image/logo.png";
import {
  faPhone,
  faUsers,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
//import scss
import "../../utils/styles/GlobalStyle.scss";

import React, { useState } from "react";
import "animate.css";

//import composant
import "./Navbar.scss";
import { Divide as Hamburger } from "hamburger-react";

function Navbar() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="align-page-menu">
      <nav>
        <div className="burger">
          <Hamburger
            onClick={() => setOpen(!isOpen)}
            easing="ease-in"
            color="#BC9C23"
            toggled={isOpen}
            toggle={setOpen}
            className="logo-bruger"
          />
          {!isOpen && <p>Menu</p>}
          {isOpen && <p>Fermer</p>}
        </div>

        <Link className="container-logo" to="/home">
          <img className="logo-nav" src={logo} alt="Logo" />
        </Link>
      </nav>
      {isOpen && (
        <div
          className={`menu ${
            isOpen ? "active animate__animated animate__fadeInLeft" : ""
          }`}
          id="menu"
        >
          <div className="scroll-container">
            <ul>
              <h2>
                <FontAwesomeIcon className="icon-navbar" icon={faUsers} />{" "}
                Particuliers
              </h2>
              <li>
                <a href="/insurance/health">Santé & Santé Sénior</a>
              </li>
              <li>
                <a href="/insurance/printer">Ass emprunteur</a>
              </li>
              <li>
                <a href="/insurance/tow-weels">Auto/Moto</a>
              </li>
              <li>
                <a href="/insurance/velo-trotinette">
                  Vélos électriques / Trottinettes
                </a>
              </li>
              <li>
                <a href="/insurance/rc-divers">RC Divers</a>
              </li>
              <li>
                <a href="/insurance/habitation">Habitation</a>
              </li>
              <li>
                <a href="/insurance/juridique">Protection juridique</a>
              </li>
              <li>
                <a href="/insurance/bailleur">Prévoyance</a>
              </li>
            </ul>

            <ul>
              <h2>
                <FontAwesomeIcon className="icon-navbar" icon={faBuilding} />{" "}
                Pro & Entreprises
              </h2>
              <li>
                <a href="/insurance/auto-pro">Auto Pro/ Flotte de véhicules</a>
              </li>
              <li>
                <a href="/insurance/moto-pro">Prévoyance</a>
              </li>
              <li>
                <a href="/insurance/rc-divers-pro">RC Divers</a>
              </li>
              <li>
                <a href="/insurance/rc-decenale">RC Décennale</a>
              </li>

              <li>
                <a href="/insurance/multirisque">Multirisques</a>
              </li>
              <li>
                <a href="/insurance/juridique-pro">Protection juridique Pro</a>
              </li>
              <li>
                <a href="/insurance/cyber">Cyber</a>
              </li>
              <li>
                <a href="/insurance/sante-tns">Santé TNS</a>
              </li>
            </ul>
          </div>

          <div className="navbar-contact">
            <ul>
              <li>
                <Link className="link-contact" to="/contact">
                  <FontAwesomeIcon className="icon-navbar" icon={faPhone} />{" "}
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div className="shadowMenu" onClick={() => setOpen(!isOpen)}></div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
