import "./login.scss";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Navbar from "../../components/Navbar";

function Login() {
  let navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    await fetch("https://backend.goldassurance.fr/api/auth/login", {
      method: "POST", // Changement de GET à POST
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formJson), // Corps de la requête avec les données du formulaire
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.token && response.userId) {
          // Stockage des cookies si l'authentification est réussie
          Cookies.set("token", response.token, {});
          Cookies.set("userId", response.userId, {});
          Cookies.set("name", response.name, {});
          navigate("/dashboard");
          window.location.reload();
        }
      });
    console.log(formJson);
  }

  return (
    <div>
      <Navbar />
      <div className="Container-page-login">
        <div className="Container-login">
          <form method="post" onSubmit={handleSubmit}>
            <h2>Connexion</h2>

            <div className="input-box">
              <input type="email" required name="email" />
              <span>Email</span>
              <i></i>
            </div>

            <div className="input-box">
              <input type="password" required name="password" />
              <span>Mot de passe</span>
              <i></i>
            </div>

            <button type="submit" className="btn">
              Se connecter
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
