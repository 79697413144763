//import data
import Banner from "../../assets/image/Baniere-Tarif.png";
import Banner10 from "../../assets/image/10.jpg";
import Banner11 from "../../assets/image/11.jpg";
import Banner12 from "../../assets/image/12.jpg";
import Banner13 from "../../assets/image/14.jpeg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faKitMedical,
  faHouse,
  faCar,
  faMedal,
  faCertificate,
  faMobile,
  faComment,
  faLightbulb,
  faBuildingColumns,
  faBicycle,
  faScaleBalanced,
  faShieldAlt,
  faUserSecret,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";

//import scss
import "../../utils/styles/GlobalStyle.scss";
import "./Home.scss";

//import composant
import Dropdown from "../../components/Dropdown";
import Navbar from "../../components/Navbar";
import Carousel from "../../components/Carousel";

function Home() {
  return (
    <div>
      <Navbar />
      {/*---------------Section Baniere tarifaire ------------------------------*/}
      <section className="container-tarif">
        <img src={Banner} alt="" />
        <div className="container-text-tarif">
          <h1>
            Complémentaire santé <span className="gold-h1">GOLD</span>
          </h1>
          <p>
            <FontAwesomeIcon className="icon-tcheck" icon={faCheck} /> Accédez
            aux meilleures offres d’assurance en temps réel et recevez des
            conseils personnalisés pour une couverture adaptée à vos besoins.
          </p>
          <p>
            <FontAwesomeIcon className="icon-tcheck" icon={faCheck} /> Trouvez
            les meilleures offres d’assurance avec notre plateforme en ligne et
            recevez des conseils personnalisés pour une couverture optimale.
          </p>
          <p>
            <FontAwesomeIcon className="icon-tcheck" icon={faCheck} /> Gérez et
            suivez vos demandes de remboursement facilement via notre
            application, avec un soutien dédié pour une indemnisation rapide.
          </p>
          <a href="insurance/health">Obtenir un tarif</a>
        </div>
      </section>

      <div className="containeurTarif">
        {/*---------------Section obtenir un tarif ------------------------------*/}

        <section className="container-get-tarif">
          <h2 className="title-particular">Obtenir un tarif:</h2>
          <span></span>
          <div className="container-categorie">
            <a href="insurance/health">
              <FontAwesomeIcon className="icon-tarif" icon={faKitMedical} />{" "}
              Santé
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/bailleur">
              <FontAwesomeIcon className="icon-tarif" icon={faHeart} />{" "}
              Prévoyance
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/printer">
              <FontAwesomeIcon
                className="icon-tarif"
                icon={faBuildingColumns}
              />{" "}
              Emprunteur
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/tow-weels">
              <FontAwesomeIcon className="icon-tarif" icon={faCar} /> Auto/Moto
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/velo-trotinette">
              <FontAwesomeIcon className="icon-tarif" icon={faBicycle} />{" "}
              Vélos/Trottinettes
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/rc-divers">
              <FontAwesomeIcon className="icon-tarif" icon={faScaleBalanced} />{" "}
              RC
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/habitation">
              <FontAwesomeIcon className="icon-tarif" icon={faHouse} />{" "}
              Habitation
            </a>
          </div>
        </section>
      </div>
      {/*---------------Section Pourquoi Gold Assurance ------------------------------*/}

      <section className="container-gold-info">
        <h2>
          Pourquoi <span>s’assurer avec GOLD ?</span>
        </h2>

        <div className="wrapper-critere">
          <div className="container-critere">
            <FontAwesomeIcon className="icon-info" icon={faMedal} />
            <h3>92 % de clients satisfaits</h3>
            <p>
              La satisfaction de nos assurés est au centre de nos attentions
            </p>
          </div>

          <span></span>

          <div className="container-critere">
            <FontAwesomeIcon className="icon-info" icon={faCertificate} />
            <h3>Expertise</h3>
            <p>Une gestion rapide et de qualité</p>
          </div>

          <span></span>

          <div className="container-critere">
            <FontAwesomeIcon className="icon-info" icon={faMobile} />
            <h3>Application mobile</h3>
            <p>
              Vos demandes de remboursement en 1 min, votre carte tiers payant
              disponible à tout moment
            </p>
          </div>

          <span></span>

          <div className="container-critere">
            <FontAwesomeIcon className="icon-info" icon={faComment} />
            <h3>Association des Assurés GOLD</h3>
            <p>
              Bénéficiez d'aides et de soutien en cas d'imprévu ou de coup dur
            </p>
          </div>

          <span></span>

          <div className="container-critere">
            <FontAwesomeIcon className="icon-info" icon={faLightbulb} />
            <h3>Acteur engagé et responsable</h3>
            <p>
              GOLD, une entreprise citoyenne avec un impact sociétal positif et
              réel
            </p>
          </div>
        </div>
      </section>

      {/*---------------Carousel ------------------------------*/}

      <Carousel />

      {/*---------------Tout savoir sur Gold Assurance ------------------------------*/}

      <section className="section-dropdown">
        <h2 className="dropdown-title">
          Tout savoir sur <span>GOLD ?</span>
        </h2>
        <div className="wrapper-content-dropdown">
          <Dropdown
            title="
Complémentaires santé"
            content="
Découvrez la complémentaire santé qui répond à vos besoins en parcourant nos différentes offres : GOLD Santé Zen, Santé Tranquillité, Santé Senior, et bien d'autres. Nos assurances santé sont conçues pour s'adapter à votre profil, qu'il s'agisse de petits budgets, de familles ou de retraités. Chaque offre est accompagnée de services qui simplifient votre quotidien, tels que des devis instantanés, une carte d’assuré accessible via votre téléphone, la téléconsultation médicale, ou encore le remboursement sous 24 heures.
"
          />
          <Dropdown
            title="Prévoyance"
            content="Les imprévus de la vie peuvent survenir à tout moment. Avec les contrats de prévoyance GOLD, vous êtes préparé pour faire face aux accidents de la vie privée. Notre contrat d’assurance prévoyance individuelle, Tempo Décès, garantit la sécurité financière de vos proches en cas de décès. Nous proposons également une couverture pour la perte d’autonomie à travers notre produit Serenassur Rente. Faites votre devis gratuit dès aujourd'hui."
          />
          <Dropdown
            title="Assurance Vélo et Trottinette"
            content="Que vous soyez cycliste ou utilisateur de trottinette, GOLD propose des assurances adaptées à votre mobilité. Nos offres couvrent les sinistres, les dommages causés à un tiers, le vol et les incidents liés à l'utilisation de votre vélo ou trottinette. Obtenez un devis en quelques minutes et circulez en toute tranquillité."
          />
          <Dropdown
            title="Assurance auto"
            content="Que vous soyez conducteur expérimenté, bonussé, malussé ou jeune conducteur, GOLD a une solution d’assurance auto pour vous. Obtenez un devis en quelques minutes, que vous recherchiez une assurance abordable ou que vous ayez rencontré des difficultés à vous assurer. Nos formules couvrent toutes les situations : sinistre, tous risques, bris de glace, dommages à un tiers, vol, et bien plus encore."
          />
          <Dropdown
            title="Assurance Habitation"
            content="Protégez votre domicile avec les assurances habitation GOLD. Nos offres couvrent vos biens contre les sinistres, les dégâts des eaux, les incendies, les cambriolages, et bien plus encore. Que vous soyez propriétaire, locataire ou colocataire, nous avons une formule adaptée à vos besoins."
          />
          <Dropdown
            title="Assurance Emprunteur"
            content="Réalisez votre projet immobilier en toute sérénité grâce à l’assurance emprunteur GOLD. Que vous financiez l’achat d’une résidence principale, secondaire ou un investissement locatif, notre assurance emprunteur vous protège en cas de décès, d’invalidité ou d’incapacité de travail. Nos garanties s’adaptent à votre profil et vous permettent de sécuriser votre emprunt tout en obtenant un devis rapide et personnalisé. Assurez-vous l’avenir en toute tranquillité."
          />
        </div>
      </section>

     
      {/*---------------Section Baniere tarifaire ------------------------------*/}
      <section className="container-tarif-pro">
      <h1 className="section-pro">
      L'Assurance Gold pour les Professionnels
      </h1>
        <div className="container-text-tarif-pro">
          <h2>
            Notre Prévoyance <span className="gold-h1">GOLD</span>
          </h2>
          <p>
            <FontAwesomeIcon className="icon-tcheck" icon={faCheck} /> Protégez
            votre avenir et celui de vos proches avec des solutions adaptées à
            toutes les situations.
          </p>
          <p>
            <FontAwesomeIcon className="icon-tcheck" icon={faCheck} /> Des
            garanties flexibles pour couvrir l’essentiel : décès, invalidité,
            incapacité de travail.
          </p>
          <p>
            <FontAwesomeIcon className="icon-tcheck" icon={faCheck} /> Une
            tranquillité d’esprit au quotidien grâce à une couverture sur
            mesure.
          </p>
          <a href="insurance/moto-pro">Obtenir un tarif</a>
        </div>
        <img src={Banner13} alt="" /> 
      </section>

      {/*---------------Section obtenir un tarif ------------------------------*/}
      <div className="containeurTarif">
        <section className="container-get-tarif">
          <h2 className="title-pro">Obtenir un tarif:</h2>
          <span></span>
          <div className="container-categorie">
            <a href="insurance/sante-tns">
              <FontAwesomeIcon className="icon-tarif" icon={faKitMedical} />{" "}
              Santé TNS
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/moto-pro">
              <FontAwesomeIcon className="icon-tarif" icon={faHeart} />{" "}
              Prévoyance Pro
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/rc-decenale">
              <FontAwesomeIcon className="icon-tarif" icon={faShieldAlt} /> RC
              Décennale
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/auto-pro">
              <FontAwesomeIcon className="icon-tarif" icon={faCar} /> Auto Pro
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/cyber">
              <FontAwesomeIcon className="icon-tarif" icon={faUserSecret} />{" "}
              Cyber
            </a>
          </div>
          <div className="container-categorie">
            <a href="insurance/juridique-pro">
              <FontAwesomeIcon className="icon-tarif" icon={faScaleBalanced} />{" "}
              Juridique Pro
            </a>
          </div>
        </section>
      </div>

      <section className="section-cards">
        <div className="container-cards">
          <div
            className="cards"
            style={{ backgroundImage: `url(${Banner10})` }}
          >
            <div className="shadow-image"></div>
            <div className="container-content">
              <h2>RC décenale</h2>
              <p>
                Protégez votre activité professionnelle avec l'assurance
                Responsabilité Civile Décennale GOLD, qui couvre pendant 10 ans
                les dommages liés à vos travaux de construction.
              </p>
              <a href="/insurance/rc-decenale">En savoir plus</a>
            </div>
          </div>

          <div
            className="cards"
            style={{ backgroundImage: `url(${Banner11})` }}
          >
            <div className="shadow-image"></div>
            <div className="container-content">
              <h2>RC divers profesionnel</h2>
              <p>
                Protégez votre activité professionnelle avec l'assurance
                Responsabilité Civile Divers Professionnels GOLD.
              </p>
              <a href="/insurance/rc-divers-pro">En savoir plus</a>
            </div>
          </div>

          <div
            className="cards"
            style={{ backgroundImage: `url(${Banner12})` }}
          >
            <div className="shadow-image"></div>
            <div className="container-content">
              <h2>Multirique profesionnel</h2>
              <p>
                Protégez votre entreprise avec l'assurance Multirisque
                Professionnel GOLD. Cette couverture complète protège vos
                locaux, équipements, et marchandises contre les sinistres
                (incendie, dégât des eaux, vol)
              </p>
              <a href="/insurance/multirisque">En savoir plus</a>
            </div>
          </div>
        </div>
      </section>

      {/*---------------GOLD, l'assurance en plus facile ------------------------------*/}
      <section className="section-dropdown">
        <h2 className="dropdown-title">
          GOLD, l'assurance en <span>plus facile</span>
        </h2>
        <div className="wrapper-content-dropdown">
          <Dropdown
            title="
Bien-Être et Santé"
            content="Avec GOLD, prenez soin de votre santé et de celle de vos proches grâce à nos complémentaires santé sur mesure. Nos solutions, telles que Santé Zen et Santé Tranquillité, sont élaborées pour s'adapter à tous les profils, que vous soyez étudiant, parent ou retraité. Bénéficiez d'un accès rapide à des services essentiels : des devis immédiats, une application mobile pour gérer votre santé, la téléconsultation à portée de main, et un remboursement rapide pour plus de tranquillité."
          />
          <Dropdown
            title="Sécurité Financière"
            content="Ne laissez pas les imprévus perturber votre sérénité financière. Les solutions de prévoyance GOLD vous offrent une couverture solide. Grâce à notre contrat Tempo Décès, vos proches bénéficient d'une protection financière en cas de décès. De plus, notre assurance Serenassur Rente vous aide à faire face aux situations de perte d’autonomie. Obtenez un devis gratuit et choisissez une couverture qui vous protège, vous et vos proches."
          />
          <Dropdown
            title="Protection lors de vos Voyages"
            content="Explorez le monde sans souci avec les assurances GOLD, conçues pour les aventuriers et expatriés. Que vous partiez en vacances ou que vous vous installiez à l'étranger, nos contrats vous offrent une protection complète en cas d’accidents ou d'imprévus. Profitez d’un service client dédié et d’un devis personnalisé pour partir l’esprit léger."
          />
          <Dropdown
            title="Assurance Auto Simplifiée"
            content="Assurez votre véhicule avec GOLD et roulez en toute confiance. Que vous soyez un conducteur chevronné ou un nouveau venu sur la route, nos offres d’assurance auto sont adaptées à vos besoins spécifiques. Obtenez un devis rapide et explorez nos couvertures : protection contre les accidents, assistance routière, bris de glace, et plus encore. Avec GOLD, votre sécurité sur la route est notre priorité."
          />
        </div>
      </section>
    </div>
  );
}
export default Home;
