import React, { useState } from "react";
import Navbar from "../../../../components/Navbar";
import "../../../../utils/styles/GlobalStyle.scss";
import "../../InsuranceForm.scss";

const Form = () => {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
    messages: "",
  });

  const [errors, setErrors] = useState({});

  // Gestion des changements dans les champs du formulaire
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://backend.goldassurance.fr/api/Particular", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      
      const result = await response.json();

      if (response.status === 201) {
        alert("Questionnaire soumis avec succès");
        setErrors({});
        setFormData({
          nom: "",
          prenom: "",
          email: "",
          telephone: "",
          messages: "",
        });
      } else if (response.status === 400) {
        setErrors(result.errors);
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire :", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="wraper-page">
        <form className="container-insurance-form" onSubmit={handleSubmit}>
          <div>
            <label>Nom :</label>
            <input
              type="text"
              name="nom"
              value={formData.nom}
              onChange={handleChange}
            />
            {errors.nom && <p>{errors.nom}</p>}
          </div>
          <div>
            <label>Prénom :</label>
            <input
              type="text"
              name="prenom"
              value={formData.prenom}
              onChange={handleChange}
            />
            {errors.prenom && <p>{errors.prenom}</p>}
          </div>
          <div>
            <label>Email :</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p>{errors.email}</p>}
          </div>
          <div>
            <label>Téléphone :</label>
            <input
              type="tel"
              name="telephone"
              value={formData.telephone}
              onChange={handleChange}
            />
            {errors.telephone && <p>{errors.telephone}</p>}
          </div>
          <div>
            <label>
              Messages :
              <textarea
                name="messages"
                value={formData.messages}
                onChange={handleChange}
              />
            </label>
          </div>
          <button type="submit">Soumettre</button>
        </form>
      </div>
    </div>
  );
};

export default Form;
