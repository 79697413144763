import React, { useState } from "react";
import Navbar from "../../../../components/Navbar";
import "../../../../utils/styles/GlobalStyle.scss";
import "../../InsuranceForm.scss";

function InsuranceForm() {
  const [formData, setFormData] = useState({
    dateEffetContrat: "",
    dejaAdherent: "",
    assurePlus12Mois: "",
    dateEffet: "",
    futursAssures: [
      {
        email: "",
        telephone: "",
        prenom: "",
        nom: "",
        dateNaissance: "",
        paysCodePostal: "",
        regimeObligatoire: "",
        statut: "",
        civilite: "",
      },
    ],
    hospitalisation: "",
    soinsCourants: "",
    dentaire: "",
    optique: "",
    renfortProthesesAuditives: "",
    renfortMedecinesNaturelles: "",
    messages: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFutursAssuresChange = (index, e) => {
    const { name, value } = e.target;
    const nouveauxAssures = [...formData.futursAssures];
    nouveauxAssures[index][name] = value;
    setFormData({ ...formData, futursAssures: nouveauxAssures });
  };

  const ajouterAssure = () => {
    setFormData({
      ...formData,
      futursAssures: [
        ...formData.futursAssures,
        {
          email: "",
          telephone: "",
          prenom: "",
          nom: "",
          dateNaissance: "",
          paysCodePostal: "",
          regimeObligatoire: "",
          statut: "",
          civilite: "",
        },
      ],
    });
  };

  const supprimerAssure = (index) => {
    const nouveauxAssures = formData.futursAssures.filter((_, i) => i !== index);
    setFormData({ ...formData, futursAssures: nouveauxAssures });
  };

  const validateForm = () => {
    const newErrors = {};
    // Validation des champs requis
    if (!formData.dateEffetContrat) newErrors.dateEffetContrat = "Ce champ est requis.";
    if (!formData.dejaAdherent) newErrors.dejaAdherent = "Ce champ est requis.";
    if (formData.dejaAdherent === "oui" && !formData.assurePlus12Mois) newErrors.assurePlus12Mois = "Ce champ est requis.";
    if (formData.dejaAdherent === "non" && !formData.dateEffet) newErrors.dateEffet = "Ce champ est requis.";

    // Validation de chaque futur assuré
    formData.futursAssures.forEach((assure, index) => {
      if (!assure.civilite) newErrors[`futursAssures[${index}].civilite`] = "Ce champ est requis.";
      if (!assure.prenom) newErrors[`futursAssures[${index}].prenom`] = "Ce champ est requis.";
      if (!assure.nom) newErrors[`futursAssures[${index}].nom`] = "Ce champ est requis.";
      if (!assure.dateNaissance) newErrors[`futursAssures[${index}].dateNaissance`] = "Ce champ est requis.";
      if (!assure.paysCodePostal) newErrors[`futursAssures[${index}].paysCodePostal`] = "Ce champ est requis.";
      if (!assure.regimeObligatoire) newErrors[`futursAssures[${index}].regimeObligatoire`] = "Ce champ est requis.";
      if (!assure.statut) newErrors[`futursAssures[${index}].statut`] = "Ce champ est requis.";
      
      // Validation email
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!assure.email) {
        newErrors[`futursAssures[${index}].email`] = "Ce champ est requis.";
      } else if (!emailPattern.test(assure.email)) {
        newErrors[`futursAssures[${index}].email`] = "Adresse e-mail invalide.";
      }

      // Validation téléphone (10 chiffres)
      if (!assure.telephone) {
        newErrors[`futursAssures[${index}].telephone`] = "Ce champ est requis.";
      } else if (!/^\d{10}$/.test(assure.telephone)) {
        newErrors[`futursAssures[${index}].telephone`] = "Numéro de téléphone invalide. Il doit contenir 10 chiffres.";
      }
    });

    if (!formData.hospitalisation) newErrors.hospitalisation = "Ce champ est requis.";
    if (!formData.soinsCourants) newErrors.soinsCourants = "Ce champ est requis.";
    if (!formData.dentaire) newErrors.dentaire = "Ce champ est requis.";
    if (!formData.optique) newErrors.optique = "Ce champ est requis.";
    if (!formData.renfortProthesesAuditives) newErrors.renfortProthesesAuditives = "Ce champ est requis.";
    if (!formData.renfortMedecinesNaturelles) newErrors.renfortMedecinesNaturelles = "Ce champ est requis.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    fetch("https://backend.goldassurance.fr/api/healt", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            setErrors(data.errors || {});
            throw new Error("Erreur réseau");
          });
        }
        return response.json();
      })
      .then((data) => {
        alert("Questionnaire soumis avec succès");
      })
      .catch((error) => console.error("Erreur :", error));
  };
  
  return (
    <div>
      <Navbar />
      <div className="wraper-page">
        <form className="container-insurance-form" onSubmit={handleSubmit}>
          <div className="form-section">
            <label>À quelle date le contrat doit-il prendre effet ?</label>
            <input
              type="date"
              name="dateEffetContrat"
              value={formData.dateEffetContrat}
              onChange={handleChange}
            />
            {errors.dateEffetContrat && (
              <p className="error">{errors.dateEffetContrat}</p>
            )}
          </div>

          <div className="form-section">
            <label>Êtes-vous déjà adhérent à un contrat individuel ?</label>
            <input
              type="radio"
              name="dejaAdherent"
              value="oui"
              checked={formData.dejaAdherent === "oui"}
              onChange={handleChange}
            />{" "}
            Oui
            <input
              type="radio"
              name="dejaAdherent"
              value="non"
              checked={formData.dejaAdherent === "non"}
              onChange={handleChange}
            />{" "}
            Non
            {errors.dejaAdherent && (
              <p className="error">{errors.dejaAdherent}</p>
            )}
          </div>

          {formData.dejaAdherent === "oui" && (
            <div className="form-section">
              <label>Est-il assuré depuis plus de 12 mois ?</label>
              <input
                type="radio"
                name="assurePlus12Mois"
                value="oui"
                checked={formData.assurePlus12Mois === "oui"}
                onChange={handleChange}
              />{" "}
              Oui
              <input
                type="radio"
                name="assurePlus12Mois"
                value="non"
                checked={formData.assurePlus12Mois === "non"}
                onChange={handleChange}
              />{" "}
              Non
              {errors.assurePlus12Mois && (
                <p className="error">{errors.assurePlus12Mois}</p>
              )}
            </div>
          )}

          {formData.dejaAdherent === "non" && (
            <div className="form-section">
              <label>Date d'effet *</label>
              <input
                type="date"
                name="dateEffet"
                value={formData.dateEffet}
                onChange={handleChange}
              />
              {errors.dateEffet && <p className="error">{errors.dateEffet}</p>}
            </div>
          )}

          <div className="form-section">
            <label>Qui sont les futurs assurés ?</label>
            {formData.futursAssures.map((assure, index) => (
              <div key={index} className="futurs-assures">
                <select
                  name="civilite"
                  value={assure.civilite}
                  onChange={(e) => handleFutursAssuresChange(index, e)}
                >
                  <option value="">Civilite</option>
                  <option value="Mr/Mme">Monsieur/Madame</option>
                  <option value="Conjoint">Conjoint</option>
                  <option value="Enfant">Enfant</option>
                </select>
                {errors[`futursAssures[${index}].civilite`] && (
                  <p className="error">
                    {errors[`futursAssures[${index}].civilite`]}
                  </p>
                )}
                <input
                  type="text"
                  name="prenom"
                  placeholder="Prénom"
                  value={assure.prenom}
                  onChange={(e) => handleFutursAssuresChange(index, e)}
                />
                {errors[`futursAssures[${index}].prenom`] && (
                  <p className="error">
                    {errors[`futursAssures[${index}].prenom`]}
                  </p>
                )}

                <input
                  type="text"
                  name="nom"
                  placeholder="Nom"
                  value={assure.nom}
                  onChange={(e) => handleFutursAssuresChange(index, e)}
                />
                {errors[`futursAssures[${index}].nom`] && (
                  <p className="error">
                    {errors[`futursAssures[${index}].nom`]}
                  </p>
                )}
                {/* New email field */}
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) => handleFutursAssuresChange(index, e)}
                />
                 {errors[`futursAssures[${index}].email`] && (
                  <p className="error">
                    {errors[`futursAssures[${index}].email`]}
                  </p>
                )}

                <input
                  type="text"
                  name="telephone"
                  placeholder="Telehpone"
                  value={formData.telephone}
                  onChange={(e) => handleFutursAssuresChange(index, e)}
                />
                {errors[`futursAssures[${index}].telephone`] && (
                  <p className="error">
                    {errors[`futursAssures[${index}].telephone`]}
                  </p>
                )}

                <input
                  type="date"
                  name="dateNaissance"
                  placeholder="Date de naissance"
                  value={assure.dateNaissance}
                  onChange={(e) => handleFutursAssuresChange(index, e)}
                />
                {errors[`futursAssures[${index}].dateNaissance`] && (
                  <p className="error">
                    {errors[`futursAssures[${index}].dateNaissance`]}
                  </p>
                )}

                <input
                  type="text"
                  name="paysCodePostal"
                  placeholder="Pays/Code postal"
                  value={assure.paysCodePostal}
                  onChange={(e) => handleFutursAssuresChange(index, e)}
                />
                {errors[`futursAssures[${index}].paysCodePostal`] && (
                  <p className="error">
                    {errors[`futursAssures[${index}].paysCodePostal`]}
                  </p>
                )}

                <input
                  type="text"
                  name="regimeObligatoire"
                  placeholder="Régime obligatoire"
                  value={assure.regimeObligatoire}
                  onChange={(e) => handleFutursAssuresChange(index, e)}
                />
                {errors[`futursAssures[${index}].regimeObligatoire`] && (
                  <p className="error">
                    {errors[`futursAssures[${index}].regimeObligatoire`]}
                  </p>
                )}

                <input
                  type="text"
                  name="statut"
                  placeholder="Statut"
                  value={assure.statut}
                  onChange={(e) => handleFutursAssuresChange(index, e)}
                />
                {errors[`futursAssures[${index}].statut`] && (
                  <p className="error">
                    {errors[`futursAssures[${index}].statut`]}
                  </p>
                )}

                <button type="button" onClick={() => supprimerAssure(index)}>
                  Supprimer
                </button>
              </div>
            ))}
            <button type="button" onClick={ajouterAssure}>
              Ajouter un assuré
            </button>
          </div>

          <div className="form-section">
            <label>Nature du projet</label>
            <div className="option-group">
              <label>Hospitalisation :</label>
              <select
                name="hospitalisation"
                value={formData.hospitalisation}
                onChange={handleChange}
              >
                <option value="">Sélectionnez</option>
                <option value="0">0%</option>
                <option value="100">100%</option>
                <option value="150">150%</option>
                <option value="250">250%</option>
                <option value="350">350%</option>
              </select>
              {errors.hospitalisation && (
                <p className="error">{errors.hospitalisation}</p>
              )}
            </div>

            <div className="option-group">
              <label>Soins courants :</label>
              <select
                name="soinsCourants"
                value={formData.soinsCourants}
                onChange={handleChange}
              >
                <option value="">Sélectionnez</option>
                <option value="0">0%</option>
                <option value="100">100%</option>
                <option value="150">150%</option>
                <option value="250">250%</option>
                <option value="350">350%</option>
              </select>
              {errors.soinsCourants && (
                <p className="error">{errors.soinsCourants}</p>
              )}
            </div>

            <div className="option-group">
              <label>Dentaire :</label>
              <select
                name="dentaire"
                value={formData.dentaire}
                onChange={handleChange}
              >
                <option value="">Sélectionnez</option>
                <option value="0">0%</option>
                <option value="100">100%</option>
                <option value="150">150%</option>
                <option value="250">250%</option>
                <option value="350">350%</option>
              </select>
              {errors.dentaire && <p className="error">{errors.dentaire}</p>}
            </div>

            <div className="option-group">
              <label>Optique :</label>
              <select
                name="optique"
                value={formData.optique}
                onChange={handleChange}
              >
                <option value="">Sélectionnez</option>
                <option value="0">0%</option>
                <option value="100">100%</option>
                <option value="150">150%</option>
                <option value="250">250%</option>
                <option value="350">350%</option>
              </select>
              {errors.optique && <p className="error">{errors.optique}</p>}
            </div>

            <div className="option-group">
              <label>Renfort Prothèses auditives :</label>
              <input
                type="radio"
                name="renfortProthesesAuditives"
                value="oui"
                checked={formData.renfortProthesesAuditives === "oui"}
                onChange={handleChange}
              />{" "}
              Oui
              <input
                type="radio"
                name="renfortProthesesAuditives"
                value="non"
                checked={formData.renfortProthesesAuditives === "non"}
                onChange={handleChange}
              />{" "}
              Non
              {errors.renfortProthesesAuditives && (
                <p className="error">{errors.renfortProthesesAuditives}</p>
              )}
            </div>

            <div className="option-group">
              <label>Renfort Médecines naturelles :</label>
              <input
                type="radio"
                name="renfortMedecinesNaturelles"
                value="oui"
                checked={formData.renfortMedecinesNaturelles === "oui"}
                onChange={handleChange}
              />{" "}
              Oui
              <input
                type="radio"
                name="renfortMedecinesNaturelles"
                value="non"
                checked={formData.renfortMedecinesNaturelles === "non"}
                onChange={handleChange}
              />{" "}
              Non
              {errors.renfortMedecinesNaturelles && (
                <p className="error">{errors.renfortMedecinesNaturelles}</p>
              )}
            </div>
          </div>

          <div className="form-section">
            <label>Messages</label>
            <textarea
              name="messages"
              value={formData.messages}
              onChange={handleChange}
            />
          </div>

          <button type="submit">Soumettre</button>
        </form>
      </div>
    </div>
  );
}

export default InsuranceForm;
