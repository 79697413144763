//import scss
import "../../../../utils/styles/GlobalStyle.scss";
import "../../Insurance.scss";

//import composant
import Navbar from "../../../../components/Navbar";

function Health() {
  return (
    <div>
      <Navbar />
      <section className="container-insurance">
        <div className="container-information">
          <h2>Toutes les solutions de prévoyance</h2>

          <p>
            Indiquez vos besoins de couverture pour obtenir un devis gratuit,
            rapide et personnalisé. Les solutions de prévoyance vous protègent,
            ainsi que vos proches, contre les risques liés à la vie, tels que
            l'invalidité, l'incapacité de travail ou le décès. Anticipez les
            imprévus et assurez l'avenir en toute sérénité.
          </p>
        </div>
        <div className="container-estimate">
          <h2>Obtenez votre devis prévoyance en quelques minutes</h2>
          <h3>Quels types de garanties souhaitez-vous souscrire ?</h3>

          <div className="tarif-button">
            <a href="particular/form">Obtenir un tarif</a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Health;