import React, { useState } from 'react';
import Navbar from "../../../../components/Navbar";
import "../../../../utils/styles/GlobalStyle.scss";
import "../../InsuranceForm.scss";

const Form = () => {
  const [formData, setFormData] = useState({
    statut: '',            // Statut
    nomSociete: '',       // Nom de la Société
    numeroSiren: '',      // Numéro SIREN
    email: '',            // Adresse Mail
    telephone: '',        // Numéro de Téléphone
    messages: ''          // Messages
  });

  const [errors, setErrors] = useState({});

  // Gestion des changements dans les champs du formulaire
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://backend.goldassurance.fr/api/Professional', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
        
      });
      console.log(formData)
      const result = await response.json();

      if (response.status === 201) {
        alert("Formulaire soumis avec succès");
        setErrors({});
        setFormData({
          statut: '',
          nomSociete: '',
          numeroSiren: '',
          email: '',
          telephone: '',
          messages: ''  // Réinitialisation du champ messages
        });
      } else if (response.status === 400) {
        setErrors(result.errors);
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire :", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className='wraper-page'>
        <form className="container-insurance-form" onSubmit={handleSubmit}>
          <div>
            <label>Statut :</label>
            <input
              type="text"
              name="statut"
              value={formData.statut}
              onChange={handleChange}
            />
            {errors.statut && <p>{errors.statut}</p>}
          </div>
          <div>
            <label>Nom de la Société :</label>
            <input
              type="text"
              name="nomSociete"
              value={formData.nomSociete}
              onChange={handleChange}
            />
            {errors.nomSociete && <p>{errors.nomSociete}</p>}
          </div>
          <div>
            <label>Numéro SIREN :</label>
            <input
              type="text"
              name="numeroSiren"
              value={formData.numeroSiren}
              onChange={handleChange}
            />
            {errors.numeroSiren && <p>{errors.numeroSiren}</p>}
          </div>
          <div>
            <label>Adresse Mail :</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p>{errors.email}</p>}
          </div>
          <div>
            <label>Numéro de Téléphone :</label>
            <input
              type="tel"
              name="telephone"
              value={formData.telephone}
              onChange={handleChange}
            />
            {errors.telephone && <p>{errors.telephone}</p>}
          </div>
          <div>
            <label>
              Messages :
              <textarea
                name="messages"
                value={formData.messages}
                onChange={handleChange}
              />
            </label>
          </div>
          <button type="submit">Soumettre</button>
        </form>
      </div>
    </div>
  );
};

export default Form;
