//import data
import You from "../../../assets/image/you.png"
import Couple from "../../../assets/image/couple.png"
import Familly from "../../../assets/image/familly.png"
import Youchild from "../../../assets/image/you&child.png"

//import scss
import "../../../utils/styles/GlobalStyle.scss";
import "../Insurance.scss";

//import composant
import Navbar from "../../../components/Navbar";

function Health() {
  return (
    <div>
      <Navbar />
      <section className="container-insurance">
        <div className="container-information">
          <h2>Toutes les mutuelles santé</h2>

          <p>Sélectionnez votre profil et indiquez vos besoins de couverture sur chaque poste santé pour obtenir un devis gratuit, rapide et personnalisé de la meilleure complémentaire santé disponible.</p>
        </div>
        <div className="container-estimate">
          <h2>Votre devis santé en quelques minutes</h2>
          <h3>Qui souhaitez-vous assurer ?</h3>

          <div className="container-choice-form">
            <a href="health/form"> <img src={You} alt="" /> Vous</a>
            <a href="health/form"><img src={Couple} alt="" /> Votre couple</a>
            <a href="health/form"><img src={Youchild} alt="" /> Vous et vos enfants</a>
            <a href="health/form"><img src={Familly} alt="" /> Toute la famille</a>
          </div>
        </div>
      </section>
     
    </div>
  );
}
export default Health;
