import React, { useState } from "react";
import Navbar from "../../../../components/Navbar";

const QuestionnaireForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    natureProjet: "",
    typeProjet: "",
    dateEffet: "",
    souscripteur: "",
    assurePrincipal: {
      civilite: "",
      prenom: "",
      nom: "",
      email:"",
      telephone:"",
      dateNaissance: "",
      paysCodePostal: "",
      statut: "",
      profession: "",
      fumeur: "",
      demenagementEtranger: "",
      deplacementPro: "",
      manutentionChargesLourdes: "",
      activiteHauteur: "",
      creditsEnCours: "",
    },
    coEmprunteur: {
      civilite: "",
      prenom: "",
      nom: "",
      dateNaissance: "",
      paysCodePostal: "",
      statut: "",
      profession: "",
      fumeur: "",
      demenagementEtranger: "",
      deplacementPro: "",
      manutentionChargesLourdes: "",
      activiteHauteur: "",
      creditsEnCours: "",
    },
    typePret: "",
    organismePreteur: "",
    montantPret: "",
    tauxPret: "",
    dureePret: "",
    periodiciteRemboursement: "",
    typeGarantie: "",
    couvrirPourcentage: "",
    couvertureDureePret: "",
    messages: "",
  });

  console.log(currentStep);
  const handleNext = () => {
    if (validateCurrentStep()) {
      setCurrentStep(currentStep + 1); // Ne passe à l'étape suivante que si la validation est réussie
    } else {
      alert(
        "Veuillez remplir tous les champs requis avant de passer à l'étape suivante."
      );
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };
  const validateCurrentStep = () => {
    console.log(
      "Validation de l'étape : ",
      currentStep,
      "Données actuelles : ",
      formData
    );

    switch (currentStep) {
      case 1:
        return (
          formData.natureProjet &&
          formData.typeProjet &&
          formData.dateEffet &&
          formData.souscripteur
        );
      case 2:
        return (
          formData.assurePrincipal.civilite &&
          formData.assurePrincipal.prenom &&
          formData.assurePrincipal.nom
        );
      case 3:
        return (
          formData.coEmprunteur.civilite &&
          formData.coEmprunteur.prenom &&
          formData.coEmprunteur.nom
        );
      case 4:
        console.log("Validation des données pour l'étape 4 :", {
          typePret: formData.typePret,
          organismePreteur: formData.organismePreteur,
          montantPret: formData.montantPret,
          tauxPret: formData.tauxPret,
          dureePret: formData.dureePret,
          periodiciteRemboursement: formData.periodiciteRemboursement,
        });
        return (
          formData.typePret &&
          formData.organismePreteur &&
          formData.montantPret &&
          formData.tauxPret &&
          formData.dureePret &&
          formData.periodiciteRemboursement
        );
      case 5:
        return (
          formData.typeGarantie &&
          formData.couvrirPourcentage &&
          formData.couvertureDureePret
        );
      default:
        return false;
    }
  };

  console.log("la",formData)
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Créer le tableau des futurs assurés avec assurePrincipal et coEmprunteur
    const formattedFutursAssures = [
      { ...formData.assurePrincipal }, // Inclure l'assuré principal
      { ...formData.coEmprunteur }, // Inclure le co-emprunteur
    ];

    // Ajouter ce tableau à formData
    const fullFormData = {
      ...formData,
      futursAssures: formattedFutursAssures, // Ajout du tableau futursAssures
    };

    console.log("fullFormData avant soumission:", fullFormData); // Log des données avant soumission

    if (validateCurrentStep()) {
      try {
        const response = await fetch("https://backend.goldassurance.fr/api/printer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(fullFormData), // Envoi des données complètes avec futursAssures
        });

        if (response.ok) {
          alert("Formulaire soumis avec succès!");
          console.log("Formulaire envoyé:", fullFormData); // Afficher les données envoyées

          setCurrentStep(1); // Réinitialiser à la première étape
        } else {
          const errorData = await response.json();
          console.log("Erreur du backend:", errorData); // Afficher l'erreur du backend

          alert(
            "Erreur lors de la soumission : " +
              (errorData.errors || "Erreur inconnue")
          );
        }
      } catch (error) {
        console.log("Erreur lors de la soumission:", error); // Log de l'erreur de soumission
        alert("Erreur lors de la soumission : " + error.message);
      }
    } else {
      console.log("Validation échouée, formData:", fullFormData); // Afficher les données si la validation échoue
      alert("Veuillez remplir tous les champs requis.");
    }
  };

  return (
    <div>
      <Navbar />

    <div className="wraper-page">
    <form className="container-insurance-form" onSubmit={handleSubmit}>
      {currentStep === 1 && (
        <div className="form-section">
          <h2>Étape 1: Nature du Projet</h2>
          <label>
            Quelle est la nature du Projet:
            <select
              value={formData.natureProjet}
              onChange={(e) =>
                setFormData({ ...formData, natureProjet: e.target.value })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Nouvel Emprunt">Nouvel Emprunteur</option>
              <option value="Résiliation infra-annuelle">
                Résiliation infra-annuelle
              </option>
            </select>
          </label>
          <br />
          <label>
            Type de Projet:
            <select
              value={formData.typeProjet}
              onChange={(e) =>
                setFormData({ ...formData, typeProjet: e.target.value })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Rés principale">Résidence principale</option>
              <option value="Rés secondaire">Résidence secondaire</option>
              <option value="Travaux rés principal">
                Travaux résidence principal
              </option>
              <option value="Travaux rés secondaire">
                Travaux résidence secondaire
              </option>
              <option value="Investissement locatif">
                Investissement locatif
              </option>
            </select>
          </label>
          <br />
          <label>
            Date d’Effet:
            <input
              type="date"
              value={formData.dateEffet}
              onChange={(e) =>
                setFormData({ ...formData, dateEffet: e.target.value })
              }
            />
          </label>
          <br />
          <label>
            Souscripteur:
            <select
              value={formData.souscripteur}
              onChange={(e) =>
                setFormData({ ...formData, souscripteur: e.target.value })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Personne physique">Personne physique</option>
              <option value="Personne morale">Personne morale</option>
            </select>
          </label>
          <br />
        </div>
      )}

{currentStep === 2 && (
  <div className="form-section">
    <h2>Étape 2: Assuré Principal</h2>
    
    <label>
      Civilité:
      <select
        value={formData.assurePrincipal.civilite}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              civilite: e.target.value,
            },
          })
        }
      >
        <option value="">Sélectionner</option>
        <option value="Monsieur">Monsieur</option>
        <option value="Madame">Madame</option>
      </select>
    </label>
    <br />
    
    <label>
      Prénom:
      <input
        type="text"
        value={formData.assurePrincipal.prenom}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              prenom: e.target.value,
            },
          })
        }
      />
    </label>
    <br />
    
    <label>
      Nom:
      <input
        type="text"
        value={formData.assurePrincipal.nom}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              nom: e.target.value,
            },
          })
        }
      />
    </label>
    <br />
    
    <label>
      Date de naissance:
      <input
        type="date"
        value={formData.assurePrincipal.dateNaissance}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              dateNaissance: e.target.value,
            },
          })
        }
      />
    </label>
    <br />
    
    <label>
      Pays et code postal de naissance:
      <input
        type="text"
        value={formData.assurePrincipal.paysCodePostal}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              paysCodePostal: e.target.value,
            },
          })
        }
      />
    </label>
    <br />
    
    <label>
      Statut:
      <input
        type="text"
        value={formData.assurePrincipal.statut}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              statut: e.target.value,
            },
          })
        }
      />
    </label>
    <br />
    
    <label>
      Profession:
      <input
        type="text"
        value={formData.assurePrincipal.profession}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              profession: e.target.value,
            },
          })
        }
      />
    </label>
    <br />
    
    <label>
      Email:
      <input
        type="email"
        value={formData.assurePrincipal.email}  
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              email: e.target.value,
            },
          })
        }
      />
    </label>
    <br />
    
    <label>
      Téléphone:
      <input
        type="tel"
        value={formData.assurePrincipal.telephone}  
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              telephone: e.target.value,
            },
          })
        }
      />
    </label>
    <br />

    <label>
      A-t-il fumé ou vapoté ces deux dernières années?
      <select
        value={formData.assurePrincipal.fumeur}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              fumeur: e.target.value,
            },
          })
        }
      >
        <option value="">Sélectionner</option>
        <option value="Oui">Oui</option>
        <option value="Non">Non</option>
      </select>
    </label>
    <br />

    <label>
      A-t-il prévu de déménager à l'étranger?
      <select
        value={formData.assurePrincipal.demenagementEtranger}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              demenagementEtranger: e.target.value,
            },
          })
        }
      >
        <option value="">Sélectionner</option>
        <option value="Oui">Oui</option>
        <option value="Non">Non</option>
      </select>
    </label>
    <br />

    <label>
      A-t-il des déplacements professionnels > 15 000 km/an?
      <select
        value={formData.assurePrincipal.deplacementPro}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              deplacementPro: e.target.value,
            },
          })
        }
      >
        <option value="">Sélectionner</option>
        <option value="Oui">Oui</option>
        <option value="Non">Non</option>
      </select>
    </label>
    <br />

    <label>
      Exerce-t-il une activité avec manutention régulière de charges lourdes supérieures à 15 kg?
      <select
        value={formData.assurePrincipal.manutentionChargesLourdes}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              manutentionChargesLourdes: e.target.value,
            },
          })
        }
      >
        <option value="">Sélectionner</option>
        <option value="Oui">Oui</option>
        <option value="Non">Non</option>
      </select>
    </label>
    <br />

    <label>
      Exerce-t-il une activité à plus de 15 mètres de hauteur?
      <select
        value={formData.assurePrincipal.activiteHauteur}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              activiteHauteur: e.target.value,
            },
          })
        }
      >
        <option value="">Sélectionner</option>
        <option value="Oui">Oui</option>
        <option value="Non">Non</option>
      </select>
    </label>
    <br />

    <label>
      A-t-il des crédits en cours déjà assurés et en dehors de ce nouveau projet?
      <select
        value={formData.assurePrincipal.creditsEnCours}
        onChange={(e) =>
          setFormData({
            ...formData,
            assurePrincipal: {
              ...formData.assurePrincipal,
              creditsEnCours: e.target.value,
            },
          })
        }
      >
        <option value="">Sélectionner</option>
        <option value="Oui">Oui</option>
        <option value="Non">Non</option>
      </select>
    </label>
    <br />
  </div>
)}


      {currentStep === 3 && (
        <div className="form-section">
          <h2>Étape 3: Co-Emprunteur</h2>
          <label>
            Civilité:
            <select
              value={formData.coEmprunteur.civilite}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    civilite: e.target.value,
                  },
                })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Monsieur">Monsieur</option>
              <option value="Madame">Madame</option>
            </select>
          </label>
          <br />
          <label>
            Prénom:
            <input
              type="text"
              value={formData.coEmprunteur.prenom}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    prenom: e.target.value,
                  },
                })
              }
            />
          </label>
          <br />
          <label>
            Nom:
            <input
              type="text"
              value={formData.coEmprunteur.nom}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    nom: e.target.value,
                  },
                })
              }
            />
          </label>
          <br />
          <label>
            Date de Naissance:
            <input
              type="date"
              value={formData.coEmprunteur.dateNaissance}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    dateNaissance: e.target.value,
                  },
                })
              }
            />
          </label>
          <br />
          <label>
            Pays et Code Postal de Naissance:
            <input
              type="text"
              value={formData.coEmprunteur.paysCodePostal}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    paysCodePostal: e.target.value,
                  },
                })
              }
            />
          </label>
          <br />
          <label>
            Statut:
            <input
              type="text"
              value={formData.coEmprunteur.statut}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    statut: e.target.value,
                  },
                })
              }
            />
          </label>
          <br />
          <label>
            Profession:
            <input
              type="text"
              value={formData.coEmprunteur.profession}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    profession: e.target.value,
                  },
                })
              }
            />
          </label>
          <br />
          <label>
            A-t-il fumé ou vapoté ces deux dernières années?
            <select
              value={formData.coEmprunteur.fumeur}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    fumeur: e.target.value,
                  },
                })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </label>
          <br />
          <label>
            A-t-il prévu de déménager à l'étranger?
            <select
              value={formData.coEmprunteur.demenagementEtranger}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    demenagementEtranger: e.target.value,
                  },
                })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </label>
          <br />
          <label>
            A-t-il des déplacements professionnels > 15 000 km/an?
            <select
              value={formData.coEmprunteur.deplacementPro}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    deplacementPro: e.target.value,
                  },
                })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </label>
          <br />
          <label>
            Exerce-t-il une activité avec manutention régulière de charges
            lourdes supérieures à 15 kg?
            <select
              value={formData.coEmprunteur.manutentionChargesLourdes}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    manutentionChargesLourdes: e.target.value,
                  },
                })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </label>
          <br />
          <label>
            Exerce-t-il une activité à plus de 15 mètres de hauteur?
            <select
              value={formData.coEmprunteur.activiteHauteur}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    activiteHauteur: e.target.value,
                  },
                })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </label>
          <br />
          <label>
            A-t-il des crédits en cours déjà assurés et en dehors de ce nouveau
            projet?
            <select
              value={formData.coEmprunteur.creditsEnCours}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  coEmprunteur: {
                    ...formData.coEmprunteur,
                    creditsEnCours: e.target.value,
                  },
                })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </select>
          </label>
          <br />
        </div>
      )}

      {currentStep === 4 && (
        <div className="form-section">
          <h2>Étape 4: Détails du Prêt</h2>
          <label>
            Type de Prêt:
            <select
              value={formData.typePret}
              onChange={(e) =>
                setFormData({ ...formData, typePret: e.target.value })
              }
            >
              <option value="">Sélectionner</option>
              <option value="prêt à taux 0">Prêt à taux 0</option>
              <option value="prêt relais">
              Prêt relais
              </option>
              <option value="prêt InFine"> Prêt InFine</option>
              <option value="prêt classique">Prêt classique</option>
              <option value="Crédit-Bail">
              Crédit-Bail
              </option>
              <option value="Crédit auto">Prêt à Paliers</option>
              <option value="Prêt Différé">Prêt Différé</option>
            </select>
          </label>
          <br />
          <label>
          Nom de L’organisme Prêteur:
            <input
              type="text"
              value={formData.organismePreteur}
              onChange={(e) =>
                setFormData({ ...formData, organismePreteur: e.target.value })
              }
            />
          </label>
          <br />
          <label>
            Montant du Prêt:
            <input
              type="number"
              value={formData.montantPret}
              onChange={(e) =>
                setFormData({ ...formData, montantPret: e.target.value })
              }
            />
          </label>
          <br />
          <label>
            Taux du Prêt:
            <input
              type="number"
              step="0.01"
              value={formData.tauxPret}
              onChange={(e) =>
                setFormData({ ...formData, tauxPret: e.target.value })
              }
            />
          </label>
          <br />
          <label>
            Durée du Prêt (en mois):
            <input
              type="number"
              value={formData.dureePret}
              onChange={(e) =>
                setFormData({ ...formData, dureePret: e.target.value })
              }
            />
          </label>
          <br />
          <label>
            Périodicité du Remboursement:
            <select
              value={formData.periodiciteRemboursement}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  periodiciteRemboursement: e.target.value,
                })
              }
            >
              <option value="">Sélectionner</option>
              <option value="Mensuelle">Mensuelle</option>
              <option value="Trimestrielle">Trimestrielle</option>
              <option value="Semestrielle">Semestrielle</option>
              <option value="Annuelle">Annuelle</option>
            </select>
          </label>
          <br />
        </div>
      )}

      {currentStep === 5 && (
        <div className="form-section">
          <h2>Étape 5: Garanties et Messages</h2>
          <label>
          Décès et Perte Totale et Irréversible d’Autonomie (PTIA):
            <div>
              <label>
                <input
                  type="radio"
                  value="Oui"
                  checked={formData.typeGarantie === "Oui"}
                  onChange={(e) =>
                    setFormData({ ...formData, typeGarantie: e.target.value })
                  }
                />
                Oui
              </label>
              <label>
                <input
                  type="radio"
                  value="Non"
                  checked={formData.typeGarantie === "Non"}
                  onChange={(e) =>
                    setFormData({ ...formData, typeGarantie: e.target.value })
                  }
                />
                Non
              </label>
            </div>
          </label>
          <br />
          <label>
            Couvrir à :
            <select
              value={formData.couvrirPourcentage}
              onChange={(e) =>
                setFormData({ ...formData, couvrirPourcentage: e.target.value })
              }
            >
              <option value="">Sélectionner</option>
              <option value="25%">25%</option>
              <option value="50%">50%</option>
              <option value="75%">75%</option>
              <option value="100%">100%</option>
            </select>
          </label>
          <br />
          <label>
            Couverture sur toute la durée du prêt :
            <div>
              <label>
                <input
                  type="radio"
                  value="Oui"
                  checked={formData.couvertureDureePret === "Oui"}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      couvertureDureePret: e.target.value,
                    })
                  }
                />
                Oui
              </label>
              <label>
                <input
                  type="radio"
                  value="Non"
                  checked={formData.couvertureDureePret === "Non"}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      couvertureDureePret: e.target.value,
                    })
                  }
                />
                Non
              </label>
            </div>
          </label>
          <br />
          <label>
            Messages :
            <textarea
              value={formData.messages}
              onChange={(e) =>
                setFormData({ ...formData, messages: e.target.value })
              }
            />
          </label>
        </div>
      )}

      <div className="wraper-bouton">
        {currentStep > 1 && (
          <button type="button" onClick={handlePrev}>
            Précédent
          </button>
        )}
        {currentStep < 4 ? (
          <button type="button" onClick={handleNext}>
            Suivant
          </button>
        ) : (
          <button
            type="button"
            onClick={handleNext}
            disabled={currentStep === 5}
          >
            Suivant
          </button>
        )}
        {currentStep >= 5 && <button type="submit">Soumettre</button>}
      </div>
    </form>
    </div>
    </div>
  );
};

export default QuestionnaireForm;
