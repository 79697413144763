import { useState, useEffect } from 'react';

// Import scss
import './Carousel.scss';

// Import image
import Banner from "../../assets/image/0.jpg";
import Banner1 from "../../assets/image/1.jpg";
import Banner2 from "../../assets/image/2.jpg";
import Banner4 from "../../assets/image/4.jpg";
import Banner5 from "../../assets/image/5.jpg";
import Banner6 from "../../assets/image/6.jpg";
import Banner7 from "../../assets/image/13.png";

function Carousel() {
  const [currentOffset, setCurrentOffset] = useState(0);
  const [windowSize, setWindowSize] = useState(3);  // Default for desktop
  const [paginationFactor, setPaginationFactor] = useState(360);  // Default width for desktop

  const items = [
    { title: "Complémentaire ", titleColor: "Santé", text: "Découvrez nos complémentaires santé, de la plus protectrice à la plus économique.", images: Banner1, link: "insurance/health" },
    { title: "Assurance ", titleColor: "Prévoyance", text: "Découvrez nos assurances prévoyance, conçues pour vous protéger en cas d’imprévu.", images: Banner7, link: "insurance/bailleur" },
    { title: "Assurance ", titleColor: "Emprunteur", text: "Bénéficiez du savoir-faire d'un spécialiste de l'assurance de prêt immobilier économiser jusqu'à 50%", images: Banner2, link: "insurance/printer" },
    { title: "Assurance ", titleColor: "Auto/Moto", text: "Pour les motards, mais aussi pour assurer votre scooter et quad.", images: Banner4, link: "insurance/tow-weels" },
    { title: "Assurance ", titleColor: "Vélos/Trottinettes", text: "Si la trottinette est devenue votre moyen de locomotion favori, vous allez apprécier notre offre d'assurance trottinette et vélo électrique.", images: Banner5, link: "insurance/velo-trotinette" },
    { title: "Assurance ", titleColor: "RC", text: "Pour se couvrir contre des dommages que vous causeriez involontairement à autrui, la responsabilité civile est une garantie essentielle..", images: Banner6, link: "insurance/rc-divers" },
    { title: "Assurance ", titleColor: "Habitation", text: "Dormez tranquille, nous avons la couverture qu'il vous faut !", images: Banner, link: "insurance/habitation" }
  ];

  useEffect(() => {
    // Handle resizing for responsive behavior
    const handleResize = () => {
      const width = window.innerWidth;
      
      if (width <= 768) {
        setWindowSize(1);  // Mobile
        setPaginationFactor(380);  // Full screen width for each card
      } else if (width <= 820) {
        setWindowSize(3);  // Tablet
        setPaginationFactor(510);  // Full screen width for each card
      } else if (width <= 1024) {
        setWindowSize(3);  // Tablet
        setPaginationFactor(460);  // Full screen width for each card
      } else if (width <= 1200) {
        setWindowSize(3);  // Tablet
        setPaginationFactor(380);  // Full screen width for each card
      } else if (width <= 1366) {
        setWindowSize(4);  // Tablet
        setPaginationFactor(480);  // Full screen width for each card
      } else if (width <= 1586) {
        setWindowSize(4);  // Tablet
        setPaginationFactor(480);  // Full screen width for each card
      } else {
        setWindowSize(4.9);  // Desktop
        setPaginationFactor(360);  // Fixed width for desktop cards
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call once to set the correct size on initial load
    handleResize();

    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const atEndOfList = currentOffset <= (paginationFactor * -1) * (items.length - windowSize);
  const atHeadOfList = currentOffset === 0;

  const moveCarousel = (direction) => {
    if (direction === 1 && !atEndOfList) {
      setCurrentOffset(currentOffset - paginationFactor);
    } else if (direction === -1 && !atHeadOfList) {
      setCurrentOffset(currentOffset + paginationFactor);
    }
  };

  return (
    <section className='carousel-container'>
      <div className="card-carousel-wrapper">
        <div className={`card-carousel--nav__left${atHeadOfList ? "--disabled" : ""}`} onClick={() => !atHeadOfList && moveCarousel(-1)}></div>
        <div className="card-carousel">
          <div className="card-carousel--overflow-container">
            <div className="card-carousel-cards" style={{ transform: `translateX(${currentOffset}px)` }}>
              {items.map((item) => (
                <div key={item.title} className="card-carousel--card">
                  <img src={item.images} alt={item.title} />
                  <div className="card-carousel--card--footer">
                    <h3>{item.title} <span>{item.titleColor}</span></h3>
                    <p>{item.text}</p>
                    <a href={item.link} target="_blank" rel="noopener noreferrer">Obtenir un tarif</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={`card-carousel--nav__right${atEndOfList ? "--disabled" : ""}`} onClick={() => !atEndOfList && moveCarousel(1)}></div>
      </div>
    </section>
  )
}

export default Carousel;
