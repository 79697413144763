//import scss
import "../../../../utils/styles/GlobalStyle.scss";
import "../../Insurance.scss";

//import composant
import Navbar from "../../../../components/Navbar";

function Health() {
  return (
    <div>
      <Navbar />
      <section className="container-insurance">
        <div className="container-information">
          <h2>Toutes les mutuelles auto et moto</h2>

          <p>
            Sélectionnez votre profil et indiquez vos besoins en matière de
            couverture pour chaque type de véhicule. Obtenez un devis gratuit,
            rapide et personnalisé de la meilleure assurance auto ou moto qui
            correspond à vos attentes.
          </p>
        </div>
        <div className="container-estimate">
          <h2>Votre devis en quelques minutes</h2>
          <h3>Quel véhicule souhaitez-vous assurer ?</h3>

          <div className="tarif-button">
            <a href="particular/form-auto">Obtenir un tarif</a>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Health;
