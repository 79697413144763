//import data

//import scss
import "../../utils/styles/GlobalStyle.scss";
import "./Contact.scss";
import logoCard from "../../assets/image/logo.png"

//import composant
import Navbar from "../../components/Navbar";

function Contact() {
  return (
    <div>
      <Navbar />
      <section className="container-contact">
        <div className="container-text-contact">
          <h1>
            Tous les <span>numéros</span> de téléphone pour contacter votre
            <span> assurance GOLD</span>
          </h1>

          <p>
            GOLD Santé, Emprunteur, Prévoyance, Partenaires, Auto Moto, International ...
          </p>

          <p>
            Retrouvez la liste de tous les numéros de téléphone pour contacter
            le bon service GOLD, que vous soyez adhérent ou pas encore :
          </p>
        </div>

        <div className="container-cards-contact">
          <h2>GOLD ASSURANCE</h2>
          <h3>Vivez la différence avec Gold Bank & Assurance</h3>
          <div className="card-contact">
            <div className="decoration-card-contact"><img className="logoCard" src={logoCard} alt="" /></div>
            <div className="wrapper-content">
              <h3>Pas encore client ou déjà client ?</h3>
              <p>Pour tout renseignement complémentaire</p>
              <a href="mail:+33635533935">06 35 53 39 35 *</a>
              <a href="mailto:contact@goldassurance.fr">contact@goldassurance.fr</a>
              <p>Du lundi au vendredi de 9h à 18h</p>
              <p className="asterix">* prix d'un appel local depuis la métropole</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Contact;
